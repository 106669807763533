

































































































































































import { ApplicationUserModel, AuditModel, CategoryModel, PermissionTypeEnum, PlanItemModel, ResponseModel, ResponseTypeEnum, TenantUserModel } from '@/libs/Api';
import Page from '@/Page.vue';
import { Component, Prop, Ref, Watch } from 'vue-property-decorator';
import { cloneDeep, isEqual } from 'lodash';
import { auditsStore } from '@/libs/audits/+state/store';
import { CrudAction, CrudReponse } from '@/libs/core/+state/models/crud-action';
import { Guid } from '@/libs/common/functions/guid';
import moment from 'moment';
import { planItemsStore } from '@/libs/plan-items/+state/store';
import { applicationUserStore } from '@/libs/user-settings/+state/store';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';
import { authStore } from '@/libs/auth/+store/store';
import { AuthGetter } from '@/libs/auth/models/auth-state';

import WorkTaskAuditTab from '@/libs/work-tasks/pages/WorkTaskAuditTab.vue';
import WorkTaskFindingTab from '@/libs/work-tasks/pages/WorkTaskFindingTab.vue';
import WorkTaskMeasureTab from '@/libs/work-tasks/pages/WorkTaskMeasureTab.vue';
import { categoriesStore } from '@/libs/categories/+state/store';
import { createCrudQueryPayload } from '@/libs/core/+state/models/crud-query-payload';
import { VDA63 } from '@/audit-settings/VDA63';

@Component({
    components: {
        WorkTaskAuditTab,
        WorkTaskFindingTab,
        WorkTaskMeasureTab,
    },
})
export default class WorkTaskAuditForm extends Page {
    /* Page props */
    @Prop() id!: string;
    @Prop() tenantId!: string;

    /* Model variables */
    planItem: PlanItemModel | null = null;
    planItemOriginal: PlanItemModel | null = null;
    audit: AuditModel | null = null;
    category: CategoryModel | undefined = undefined;
    day!: string;
    auditId!: string;

    /* Component state variables */
    tab = 0;
    loading = true;
    saving = false;
    savingSilent = false;
    responseLockDialog = false;
    responseLockExistingEvidenceNumber = false;
    hasChanges = false;
    auditPerformed = false;
    savingInstructionConfirmation = false;
    planItemId: string | null = null;
    mistakeSnackbar = false;
    confirmDialog = false;

    /* User state variables */
    applicationUser: ApplicationUserModel = applicationUserStore.useGetter(CrudGetter.Detail) as ApplicationUserModel;
    tenantUser: TenantUserModel = authStore.useGetter(AuthGetter.GetTenantUser) as TenantUserModel;

    /* Others */
    closeAfterSave = false;

    /* Refs */
    @Ref() auditTab!: WorkTaskAuditTab;

    loadingFinished() {
        const scrollTop = this.getQueryInt('scroll');
        if (scrollTop) {
            this.$nextTick(() => {
                this.setQuery('scroll', null);
                document!.getElementById('layout-content')!.scrollTo({
                    top: scrollTop,
                    behavior: 'auto',
                });
            });
        }
    }

    /* Watches */
    @Watch('planItem', { deep: true })
    planItemChanged() {
        this.checkChanges();
    }

    @Watch('id')
    idChanged(v: string) {
        this.tab = 0;
        this.planItemId = v;
        this.planItem = null;
        this.planItemOriginal = null;
        this.audit = null;
        this.category = undefined;
        this.day = '';
        console.log('idChanged');
        this.load();
    }

    /* Getters */

    get isLoading() {
        return this.loading || this.auditTab?.loading == true;
    }

    get isValid(): boolean {
        return this.auditTab?.isValid();
    }

    get canEditWorkplace() {
        const permissions = authStore.useGetter(AuthGetter.GetPermissions) as PermissionTypeEnum[];
        return (this.planItem != null && this.planItem.tenantUserId == this.tenantUser.id && this.planItem.responseLock == false) || permissions.contains(PermissionTypeEnum.MistakeCorrection);
    }

    get canEditQuestions() {
        const permissions = authStore.useGetter(AuthGetter.GetPermissions) as PermissionTypeEnum[];
        return (this.planItem != null && this.planItem.tenantUserId == this.tenantUser.id && this.planItem.responseLock == false) || permissions.contains(PermissionTypeEnum.MistakeCorrection);
    }

    get canEditFiles() {
        const permissions = authStore.useGetter(AuthGetter.GetPermissions) as PermissionTypeEnum[];
        return (this.planItem != null && this.planItem.tenantUserId == this.tenantUser.id && this.planItem.responseLock == false) || permissions.contains(PermissionTypeEnum.MistakeCorrection);
    }

    get canEditCorrectiveMeasurements() {
        const permissions = authStore.useGetter(AuthGetter.GetPermissions) as PermissionTypeEnum[];
        return (this.planItem != null && this.planItem.responseLock == true && this.planItem.fullLock == false && permissions.contains(PermissionTypeEnum.Findings)) || permissions.contains(PermissionTypeEnum.MistakeCorrection);
    }

    get canCorrectMistakes() {
        const permissions = authStore.useGetter(AuthGetter.GetPermissions) as PermissionTypeEnum[];
        const hasCorrectQueryParam = this.$route.query?.correct?.toString() == '1';
        return permissions.contains(PermissionTypeEnum.MistakeCorrection) && hasCorrectQueryParam;
    }

    get routeName() {
        return this.$router.currentRoute.name;
    }

    get isRequestedInstructionsConfirmation() {
        return !this.planItem?.responseLock && this.category?.enforceInstuctionsConfirmation && this.planItem?.isIntructionsConfirmed != true;
    }

    /* Mounted */
    mounted() {
        this.closeAfterSave = false;
        if (this.id && this.id != Guid.EmptyGuid()) {
            this.planItemId = this.id;
            this.load();
        } else {
            this.planItemId = Guid.EmptyGuid();
            this.auditId = this.$router.currentRoute.params.auditId;
            this.day = moment().format('YYYY-MM-DDT00:00:00');
            this.load();
        }
        this.checkChanges();
    }

    /* Action functions */

    private load() {
        return new Promise((resolve, reject) => {
            this.loading = true;
            this.loadPlanItem()
                .then((e: PlanItemModel) => {
                    if (e.auditId) this.auditId = e.auditId;
                    this.loadAudit()
                        .then((e: AuditModel) => {
                            this.loading = false;
                        })
                        .catch((e: any) => {
                            reject(e);
                        });
                })
                .catch((e: any) => {
                    reject(e);
                });
        });
    }

    private loadPlanItem(): any {
        return new Promise((resolve, reject) => {
            if (this.planItemId == Guid.EmptyGuid()) {
                auditsStore.dispatch(CrudAction.Get, { id: this.auditId });
                this.subscribe(auditsStore, CrudReponse.Get)
                    .then((e: AuditModel) => {
                        this.planItem = {
                            id: Guid.EmptyGuid(),
                            tenantUserId: this.tenantUser.id,
                            auditId: this.auditId,
                            audit: e,
                            time: moment().format('YYYY-MM-DDTHH:mm:ss'),
                            responses: [],
                            responseLock: false,
                            fullLock: false,
                            isIntructionsConfirmed: false,
                            instructionsConfirmedOn: null,
	                        isReleased: null
                        } as PlanItemModel;
                        this.planItemOriginal = cloneDeep(this.planItem);
                        this.loadCategory(e.categoryId!)
                            .then((c: CategoryModel) => {
                                this.category = c;
                                this.hasChanges = false;
                                resolve(this.planItem);
                            })
                            .catch(e => {
                                reject(e);
                            });
                    })
                    .catch(e => {
                        reject(e);
                    });
            } else {
                this.$nextTick(() => {
                    const planItemId = this.planItemId;
                    planItemsStore.dispatch(CrudAction.Get, { id: planItemId });
                    this.subscribe(planItemsStore, CrudReponse.Get)
                        .then((e: PlanItemModel) => {
                            this.planItem = e;
                            this.planItemOriginal = cloneDeep(this.planItem);
                            this.loadCategory(e.audit!.categoryId!)
                                .then((c: CategoryModel) => {
                                    this.hasChanges = false;
                                    resolve(e);
                                })
                                .catch(e => {
                                    reject(e);
                                });
                        })
                        .catch((e: any) => {
                            reject(e);
                        });
                });
            }
        });
    }

    private loadAudit(): Promise<any> {
        return new Promise((resolve, reject) => {
            auditsStore.dispatch(CrudAction.Get, { id: this.auditId!, key: this.auditId! });
            this.subscribe(auditsStore, CrudReponse.Get, this.auditId!)
                .then((e: AuditModel) => {
                    this.audit = e;
                    resolve(e);
                })
                .catch((e: any) => {
                    reject(e);
                });
        });
    }

    loadCategory(categoryId: string): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            categoriesStore.dispatch(CrudAction.Get, { id: categoryId });
            this.subscribe(categoriesStore, CrudReponse.Get)
                .then((e: CategoryModel) => {
                    this.category = e;
                    resolve(e);
                })
                .catch((e: any) => {
                    reject(e);
                });
        });
    }

    private verifyEvidenceNumber(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            if (this.planItem == null || this.planItem!.evidenceNumber == null || this.planItem!.evidenceNumber.length == 0) {
                resolve(false);
            } else {
                planItemsStore.dispatch(
                    CrudAction.GetAll,
                    createCrudQueryPayload<PlanItemModel>(
                        undefined,
                        [
                            { field: 'evidenceNumber', op: 'eq', comparand: this.planItem!.evidenceNumber! },
                            { field: 'id', op: 'ne', comparand: this.planItem!.id ?? Guid.EmptyGuid() },
                        ],
                        undefined,
                        false,
                        'VerifyEvidenceNumber'
                    )
                );
                this.subscribe(planItemsStore, CrudReponse.GetAll, 'VerifyEvidenceNumber')
                    .then((e: PlanItemModel[]) => {
                        resolve(e.length > 0);
                    })
                    .catch(() => {
                        resolve(false);
                    });
            }
        });
    }

    private save(showResponseLock: true) {
        const scroll = Math.round(document!.getElementById('layout-content')!.scrollTop);
        this.responseLockDialog = false;
        this.confirmDialog = false;
        if (this.auditTab?.validate()) {
            const notFinishedResponses: Array<ResponseModel> = this.auditTab.getNotFinishedResponses();
            let validQuestions = true;
            if (this.audit?.category?.responseType == ResponseTypeEnum.VDA63) {
                validQuestions = this.validateVda63Questions();
            }
            if (this.canEditQuestions && !this.planItem!.responseLock && this.planItem!.isRequestedResponseLock != true && showResponseLock && validQuestions && notFinishedResponses.length == 0) {
                this.savingSilent = true;
                this.verifyEvidenceNumber().then((e: boolean) => {
                    if (this.category!.responseType == ResponseTypeEnum.ProductAudit) {
                        const isAllOk = this.auditTab.getResponses().filter(x => {
                            return x.responseParts!.filter(n => n.isOk == false).length > 0;
                        }).length == 0;
                        if (isAllOk) {
                            this.planItem!.isReleased = true;
                        }
                        else {
                            this.planItem!.isReleased = null;
                        }
                    }
                    this.savingSilent = false;
                    this.responseLockDialog = true;
                    this.responseLockExistingEvidenceNumber = e;
                });
            } else {
                if (notFinishedResponses.length > 0) {
                    this.planItem!.isRequestedResponseLock = false;
                }
                if (this.planItem!.isRequestedResponseLock) {
                    this.planItem!.time = moment().format('YYYY-MM-DDTHH:mm:ss');
                }
                this.saving = true;
                const responses: Array<ResponseModel> = this.auditTab.getResponses();
                const planItem = { ...this.planItem };
                planItem.responses = responses;
                if (this.planItemId == Guid.EmptyGuid()) {
                    planItemsStore.dispatch(CrudAction.Create, { item: planItem });
                    this.subscribe(planItemsStore, CrudReponse.Create)
                        .then((e: PlanItemModel) => {
                            this.planItemId = e.id!;
                            this.auditPerformed = true;
                            this.planItem = e;
                            this.planItemOriginal = cloneDeep(this.planItem);
                            this.saving = false;
                            if (this.closeAfterSave) this.$router.go(-1);
                            if (e.responseLock == true) {
                                this.$router.replace({ name: 'WorkTaskAuditTab', params: { tenantId: this.$router.currentRoute.params.tenantId, id: e.id! }, query: { scroll: scroll.toString() } });
                            } else {
                                this.$router.replace({ name: 'WorkTaskAuditFormExisting', params: { tenantId: this.$router.currentRoute.params.tenantId, id: e.id! }, query: { scroll: scroll.toString() } });
                            }
                        })
                        .catch(e => {
                            this.saving = false;
                            throw e;
                        });
                } else {
                    planItemsStore.dispatch(CrudAction.Update, { item: planItem });
                    this.subscribe(planItemsStore, CrudReponse.Update)
                        .then((e: PlanItemModel) => {
                            this.auditPerformed = true;
                            this.planItem = e;
                            this.planItemOriginal = cloneDeep(this.planItem);
                            this.saving = false;
                            if (this.closeAfterSave) this.$router.go(-1);
                            if (e.responseLock == true) {
                                this.$router.replace({ name: 'WorkTaskAuditTab', params: { tenantId: this.$router.currentRoute.params.tenantId, id: e.id! }, query: { scroll: scroll.toString() } });
                            } else {
                                this.$nextTick(() => {
                                    this.auditTab.refresh();
                                    this.setQuery('scroll', scroll.toString());
                                });
                            }
                        })
                        .catch(e => {
                            this.saving = false;
                            throw e;
                        });
                }
            }
        } else {
            this.mistakeSnackbar = true;
        }
    }

    validateVda63Questions(): boolean {
        const responses = this.auditTab.getResponses();
        for (const processElement of VDA63.ProcessElementsSteps) {
            if (responses.any(x => x.question?.questionGroup?.processElement == processElement.Element)) {
                if (processElement.Validation.ResponseCount != null) {
                    if (responses.count(x => x.question?.questionGroup?.processElement == processElement.Element && x.scoreValue != null && x.scoreValue != -1) < processElement.Validation.ResponseCount!) {
                        return false;
                    }
                }
                if (processElement.Validation.ProductResponseCount != null) {
                    if (
                        responses.count(x => x.question?.questionGroup?.processElement == processElement.Element && x.scoreValue != null && x.scoreValue != -1 && x.processStep?.isHidden == true && x.processStep.stepNumber == 'Product') <
                        processElement.Validation.ProductResponseCount!
                    ) {
                        return false;
                    }
                }
                if (processElement.Validation.ProcessResponseCount != null) {
                    if (
                        responses.count(x => x.question?.questionGroup?.processElement == processElement.Element && x.scoreValue != null && x.scoreValue != -1 && x.processStep?.isHidden == true && x.processStep.stepNumber == 'Process') <
                        processElement.Validation.ProcessResponseCount!
                    ) {
                        return false;
                    }
                }
                if (processElement.Validation.ProcessStepResponseCount != null) {
                    if (
                        responses
                            .where(x => x.question?.questionGroup?.processElement == processElement.Element && x.scoreValue != null && x.scoreValue != -1 && this.audit!.processSteps!.any(n => n.id == x.processStepId && n.isHidden != true))
                            .groupBy(x => x.questionId!)
                            .count() < processElement.Validation.ProcessStepResponseCount!
                    ) {
                        return false;
                    }
                }
            }
        }
        return true;
    }

    canEditAnyImplementationOfCorrectiveMeasurements() {
        if (this.planItem == null || this.planItem.responses == null) return false;
        return this.planItem.responses.any(x => this.canEditImplementationOfCorrectiveMeasurements(x));
    }

    canEditImplementationOfCorrectiveMeasurements(response: ResponseModel) {
        const permissions = authStore.useGetter(AuthGetter.GetPermissions) as PermissionTypeEnum[];
        return (
            (this.planItem != null &&
                this.planItem.responseLock == true &&
                this.planItem.fullLock == false &&
                (permissions.contains(PermissionTypeEnum.Measures) || permissions.contains(PermissionTypeEnum.MeasuresAllUsers)) &&
                response.implementerUserId == this.tenantUser.id) ||
            permissions.contains(PermissionTypeEnum.MistakeCorrection)
        );
    }

    checkChanges() {
        this.hasChanges = !isEqual(this.planItemOriginal, this.planItem) || this.auditTab?.hasChanges();
    }

    public plainToHtml(value: string) {
        return value?.replace('\n', '<br />');
    }

    confirmInstructions() {
        if (this.planItem!.id != null && this.planItem!.id != Guid.EmptyGuid()) {
            const item = { ...this.planItem };
            item.isIntructionsConfirmed = true;
            item.instructionsConfirmedOn = moment().format('YYYY-MM-DDTHH:mm:ss');
            this.savingInstructionConfirmation = true;
            planItemsStore.dispatch(CrudAction.Update, { item: item });
            this.subscribe(planItemsStore, CrudReponse.Update)
                .then((e: PlanItemModel) => {
                    this.savingInstructionConfirmation = false;
                    this.planItem!.isIntructionsConfirmed = item.isIntructionsConfirmed;
                    this.planItem!.instructionsConfirmedOn = item.instructionsConfirmedOn;
                })
                .catch(e => {
                    this.savingInstructionConfirmation = false;
                    throw e;
                });
        } else {
            this.planItem!.isIntructionsConfirmed = true;
            this.planItem!.instructionsConfirmedOn = moment().format('YYYY-MM-DDTHH:mm:ss');
        }
    }

    closeOnMobile() {
        if (this.hasChanges) this.confirmDialog = true;
        else this.$router.go(-1);
    }
}
